import { Globals } from 'src/app/_configs/globals';
import { HttpClient } from '@angular/common/http';
import { MainService } from './../../../shared/services/main.services';
import { Vitrine } from './../model/vitrine.model';
import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable, of } from 'rxjs';
import { ListItem, Pagination, SelectedFilter } from '../../../shared/model/list-item.model';
import { map, tap } from 'rxjs/operators';
import { AccountService } from '../../account/services/account.service';

@Injectable({
    providedIn: 'root'
})
export class VitrineService extends MainService {
    private selectVitrines: BehaviorSubject<Vitrine[]> = new BehaviorSubject<Vitrine[]>([]);
    constructor(private accountService: AccountService, protected httpClient: HttpClient, protected globals: Globals) {
        super(httpClient, globals);
    }

    public selectVitrinesByOrganizationId = (organizationId: number, currentLang: string, meetingId: number): Observable<ListItem[]> => {
        if (!organizationId) {
            return of([]);
        }
        const filters:SelectedFilter[]= [
            {
                field: 'isPaid',
                value: 1
            }
        ];
        let endpoint = this.uri + this.globals.endpoints.vitrine.all + `?meetingId=${meetingId}`;
        endpoint += `&${this.formatGetFilters(filters)}`;

        return this.httpClient.get(endpoint).pipe(
            tap((data) => {
                const output = data[ 'vitrines' ].map((vitrine) => new Vitrine(vitrine));
                this.selectVitrines.next(output);
            }),
            map(() => {           
                const filteredList = this.selectVitrines.value.filter((vitrine) => vitrine.organization.id === organizationId);
                if (!filteredList.length) {
                    return [];
                }
                return filteredList.map(
                    (vitrine): ListItem => {
                        const item = vitrine.toListItem(currentLang);
                        return {
                            ...item,
                            itemUrl: `/pro-meeting/${vitrine.meetingId}/showcase/${vitrine.id}/show/${vitrine.showId}`
                        } as ListItem;
                    }
                );
            })
        );

    };

    getVitrineForMeeting(meetingId: number, filters?: SelectedFilter[], pagination?: Pagination, searchText?: string): Observable<any> {
        let endpoint = this.uri + this.globals.endpoints.vitrine.all + `?meetingId=${meetingId}`;
        const text = searchText ? searchText.trim() : null;

        if (filters || pagination || text) {
            endpoint += '&';
        }

        if (filters && filters.length) {
            endpoint += this.formatGetFilters(filters);
            endpoint += pagination || text ? '&' : '';
        }
        if (pagination) {
            endpoint += this.formatPagination(pagination);
            endpoint += text ? '&' : '';
        }
        if (text) {
            endpoint += `search=${encodeURIComponent(text)}`;
        }

        // return this.httpClient.get(endpoint).map(res => new Vitrine(res));
        return this.httpClient.get(endpoint).pipe(
            map((data) => {
                if (pagination) {
                    pagination.total = data[ 'total' ];
                }
                const output = data[ 'vitrines' ].map((vitrine) => new Vitrine(vitrine));
                return output;
            })
        );
    }

    getVitrineById(id: number): Observable<Vitrine> {
        const endpoint = this.uri + this.globals.endpoints.vitrine.main + `/${id}`;
        return this.httpClient.get(endpoint).map((res) => new Vitrine(res));
    }

    createVitrine(vitrine: Vitrine): Observable<Vitrine> {
        const endpoint = this.uri + this.globals.endpoints.vitrine.main;
        return this.httpClient.post(endpoint, this.cleanUpNullValues(vitrine)).map((res: any) => new Vitrine(res.vitrine));
    }

    modifyVitrine(vitrine: Vitrine): Observable<any> {
        let endpoint = this.uri + this.globals.endpoints.vitrine.main;
        endpoint += '?organizationId=' + this.accountService.getCurrentCtxOrganizationId();
        return this.httpClient.put(endpoint, this.cleanUpNullValues(vitrine));
    }

    approveVitrine(vitrine: any): Observable<any> {
        const endpoint = this.uri + this.globals.endpoints.vitrine.approval;
        return this.httpClient.put(endpoint, vitrine);
    }

    softDeleteVitrine(vitrineId: number, organizationId: number) {
        return this.httpClient.delete(this.uri + this.globals.endpoints.vitrine.getDelete(vitrineId, organizationId));
    }
}
