import { Component, OnInit } from '@angular/core';
import { AccountService } from '../../concepts/account/services/account.service';
import { SelectedFilter, Pagination, Filter, FilterValue } from 'src/app/shared/model/list-item.model';
import { NotificationsService } from 'src/app/concepts/notifications/services/notifications.service';
import { TranslateService } from '@ngx-translate/core';
import { debounceTime, switchMap, tap } from 'rxjs/operators';
import { Notification } from 'src/app/concepts/notifications/model/notifications.model';
import { ActivatedRoute } from '@angular/router';
import { ToursService } from '@app/concepts/tours/services/tours.service';
import { RideauNotificationService } from '@app/shared/services/rideau-notification.service';

@Component({
    selector: 'app-notifications',
    templateUrl: './notifications.component.html',
    styleUrls: [ './notifications.component.scss' ]
})
export class NotificationsComponent implements OnInit {
    isReady = false;
    ctxOrganizationId: number;
    isArchiveLink: number;
    selectedFilter: SelectedFilter[] = [];
    notificationFilters: Filter[];
    defaultFilters: SelectedFilter[] = [];
    subscriptions: any;
    hideArchiveBtn: boolean;
    pagination: Pagination;
    notifications: Notification[] = [];

    constructor(
        private accountService: AccountService,
        private route: ActivatedRoute,
        private translate: TranslateService,
        private notificationsService: NotificationsService,
        private toursService: ToursService,
        private notification: RideauNotificationService
    ) { }

    ngOnInit(): void {
        this.ctxOrganizationId = this.accountService.getCurrentCtxOrganizationId();
        this.isArchiveLink = this.route.snapshot.params.archive;

        this.initFilter();
        this.initPagination();

        this.getAllNotifications();

        const sub = this.accountService.currentOrganizationChange.subscribe(() => {
            this.ctxOrganizationId = this.accountService.getCurrentCtxOrganizationId();
            this.initFilter();
            this.initPagination();

            this.getAllNotifications();
        });
        this.subscriptions = sub;
    }

    ngOnDestroy(): void {
        if (this.subscriptions) {
            this.subscriptions.unsubscribe();
        }
    }

    initFilter() {
        const filter = new Filter();
        filter.filterField = 'isArchived';
        filter.filterValues = [];
        filter.filterValues.push(
            {
                filterValueName: this.translate.instant('RECENT'),
                filterValue: 0,
                selected: this.isArchiveLink === undefined
            },
            {
                filterValueName: this.translate.instant('ARCHIVED'),
                filterValue: 1,
                selected: this.isArchiveLink !== undefined
            }
        );

        this.notificationFilters = [ filter ];
        this.hideArchiveBtn = this.isArchiveLink !== undefined;
        if (this.isArchiveLink !== undefined) {
            this.selectedFilter = [ { field: 'isArchived', value: 1 } ];
        }

        if (this.ctxOrganizationId > 0) {
            this.defaultFilters = [ { field: 'contextOrgId', value: this.ctxOrganizationId } ];
        } else {
            this.defaultFilters = [];
        }
    }

    initPagination() {
        this.pagination = new Pagination();
        this.pagination.limit = 20;
        this.pagination.offset = 0;
        this.pagination.total = 0;
    }

    onSelectFilter(filterField: string, { filterValue }: FilterValue, index?: number): void {
        this.notificationFilters.forEach((item: Filter) => {
            item.filterValues.forEach((value, valueIndex) => {
                if (index !== undefined) {
                    value.selected = index === valueIndex;
                }
            })
        });

        this.selectedFilter = [ { field: filterField, value: filterValue } ];
        this.hideArchiveBtn = filterValue ? true : false;
        this.getAllNotifications();
    }

    onPageChange(page: number) {
        this.pagination.limit = this.pagination.limit;
        this.pagination.offset = this.pagination.limit * (page - 1);
        this.getAllNotifications();
    }

    async getAllNotifications(): Promise<void> {
        this.notificationsService.getAllNotifications([ ...this.defaultFilters, ...this.selectedFilter ], this.pagination)
            .pipe(
                tap(() => this.isReady = false),
                switchMap(async (results: Notification[]) => {
                    return await this.getUpdatedNotifications(results);
                })).subscribe((data: Notification[]) => {
                    this.notifications = data;
                    // Update Notifications count
                    this.notificationsService.triggerNotificationCountUpdate();
                    this.isReady = true;
                });
    }

    async archiveNotifications(notificationId?: number) {
        this.notificationsService.archiveNotifications(notificationId)
            .pipe(
                tap(() =>{
                    if (!notificationId) {
                        this.notification.success(this.translate.instant('NOTIFICATIONS.ARCHIVE-MULTIPLE-CONFIRMATION'));
                    } else {
                        this.notification.success(this.translate.instant('NOTIFICATIONS.ARCHIVE-ONE-CONFIRMATION'));
                    }
                }),
                debounceTime(300),
                tap(() => {
                    this.getAllNotifications();
                }
                )).subscribe();
    }

    private getUpdatedNotifications = async (data: Notification[]) => {
        for (let idx of data.keys()) {
            let notification = data[ idx ];
            if (notification.tourId) {
                data[ idx ] = await this.getNotificationWithTourOrg(notification);
            }
        }
        return data;
    };

    private getNotificationWithTourOrg = async (notification: Notification): Promise<Notification> =>
        new Notification({
            ...notification,
            tourAdmin: (await this.toursService.getTourByIdForNotifications(notification.tourId).toPromise()).organization
        });
}
