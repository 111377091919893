import { Injectable } from '@angular/core';
import { environment } from '../../environments/environment';

@Injectable({
    providedIn: 'root'
})
export class Globals {
    public readonly MAXDESCRIPTIONLENGHT = 1000;
    public readonly MAXDESCRIPTIONLENGHT1000 = 1000;
    public readonly MAXDESCRIPTIONLENGHT1800 = 1800;
    public readonly MAXDESCRIPTIONLENGHT400 = 400;
    public readonly NO_ORGA = -1;
    public readonly RIDEAU_ORGID = 2;
    public readonly ROSEQ_ORGID = 310;
    public readonly QUEBECOFOLIES_ORGID = 455;
    public readonly SCENE_PRO_ORGID = 1;
    public readonly images = {
        maxHeight: 1000,
        maxWidth: 1000
    };
    public readonly locales = {
        frCA: 'fr-CA',
        frFR: 'fr-FR'
    };

    public static getLocaleString = (value: Date | null | string): string => {
        if (!value) {
            return '';
        }
        return new Date(value).toLocaleString('fr-CA', {
            day: '2-digit',
            month: 'long'
        });
    };

    public InputRichTextModules = {
        toolbar: [['bold', 'italic']]
    };
    public api = {
        APP_NAME: '',
        WEB_SERVER: environment.B2C_WSHOST,
        LOGIN: function () {
            return environment.B2C_LOGIN;
        },
        ENDPOINT: environment.BACKEND
    };
    public dateRangeFormat = 'yyyy/MM/dd HH:mm';
    public date_format_default = 'yyyy-MM-dd';
    public date_format_input = 'YYYY,M,D';
    public date_format_output = 'YYYY-MM-DD';
    public date_long_format_output = 'D MMMM YYYY';
    public emailRegex = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;
    public embeedMediaLinks = {
        vimeo: (vimId) => `https://player.vimeo.com/video/${vimId}?byline=0`,
        youtube: (youtubeId) => `https://www.youtube.com/embed/${youtubeId}`,
        soundcloud: (soundId) =>
            `https://w.soundcloud.com/player/?url=https%3A//soundcloud.com/${soundId}&color=%23ff5500&auto_play=false&hide_related=true&show_comments=false&show_user=true&show_reposts=false&show_teaser=true`
    };
    public endpoints = {
        organizations: {
            main: 'organizations',
            roles: 'organizations/roles',
            types: 'organizations/types'
        },

        etiquettes: {
            all: 'tags',
            allWithShows: 'tags?withShows=1&checkValidDate=1',
            create: 'tag',
            addShows: 'tag/shows',
            singleTag: (tagId) => `tag/${tagId}`,
            showTag: (tagId) => `tag/${tagId}/shows`,
            deleteShowTag: (tagId, showId) => `tag/${tagId}/show/${showId}`
        },
        calendars: {
            all: 'calendars',
            main: 'calendar',
            singleCalendar: (calId) => `calendar/${calId}`
        },
        program: {
            all: 'programs',
            main: 'program',
            singleProgram: (progId) => `program/${progId}`
        },
        organization: {
            main: 'organization',
            roles: 'organization/roles',
            types: 'organization/types',
            member: 'organization/member',
            finance: 'organization/finance',
            invitation: 'organization/invitation/',
            socialMedia: 'organization/social-media',
            approval: 'organization/approval',
            contact: 'organization/contact',
            getOrgaContactsEndpoint: (orgaId) => `organization/${orgaId}/contacts`,
            getDeleteOrgaContactsEndpoint: (orgaId, contactId) => `organization/${orgaId}/contact/${contactId}`,
            getDeleteOrgaEndpoint: (orgaId) => `organization/${orgaId}`,
            getBillsEndpoint: (orgaId) => `organization/${orgaId}/bills`,
            getTypesEndpoint: (orgaId) => `organization/${orgaId}/types`,
            getNetworkInfosEndpoint: (orgaId) => `organization/${orgaId}/finance`,
            getMemberEndpoint: (memberId) => `organization/member/${memberId}`,
            getMembersEndpoint: (orgaId) => `organization/${orgaId}/members`,
            getAllInvitationsEndpoint: (orgaId) => `organization/${orgaId}/invitations`,
            getInvitationEndpoint: (orgaId) => `organization/${orgaId}/invitation`,
            getSocialMediaEndpoint: (orgaId) => `organization/${orgaId}/social-media`,
            getDeleteMemberEndpoint: (orgaId, memberId) => `organization/${orgaId}/member/${memberId}`,
            getTourShows: (organizationId) => `organizations/${organizationId}/tour-shows`,
            getVisibleTourByOrg: (organizationId) => `tours/${organizationId}/visible`
        },

        network: {
            all: 'networks',
            withMembers: 'networks?withMembers=1',
            add: 'organization/network',
            getNetworkBills: (id) => `network/${id}/bills`,
            getNetworkSendBillsEndpoints: (id) => `network/${id}/sendbills`,
            getNetworkExpireBillsEndpoints: (id) => `network/${id}/expirebills`,
            getOrgaDetailEndpoint: (id) => `network/${id}`,
            getBillingEndpoint: (netId) => `network/${netId}/bill`,
            getManualPayBillEndpoint: (netId, billId, orgId) => `network/${netId}/paybill/${billId}?organizationId=${orgId}`,
            getDeleteOrgaFromNetworkEndpoint: (orgHeadId, orgId) => `organization/${orgHeadId}/network/${orgId}`,
            getNetworksToursEndpoint: (orgId) => `organization/${orgId}/networks/tours`,
            getNetworksByOrganizationEndpoint: (orgId) => `organization/${orgId}/networks`
        },

        locations: {
            address: 'address',
            countries: 'countries',
            states: 'states'
        },

        membership: {
            getMemberShipEndpoint: (networkId) => `membership-configuration?organizationHeadId=${networkId}`,
            main: 'membership-configuration'
        },

        meeting: {
            all: 'meetings',
            myparticipated: 'meetings/participated',
            main: 'meeting',
            getActivitiesEndpoint: (meetingId) => `meeting/${meetingId}/activities`,
            getMeetingEndpoint: (meetingId) => `meeting/${meetingId}`,
            getAllParticipantsEndpoint: (meetingId) => `meeting/${meetingId}/participants`,
            getParticipantEndpoint: (meetingId, participantId) => `meeting/${meetingId}/participant/${participantId}`,
            getSoftDeleteEndPoint: (meetingId, participantId) => `meeting/${meetingId}/participant/${participantId}/soft-deleted`,
            getParticipantOrgEndpoint: (meetingId) => `meeting/${meetingId}/organizations`,
            getParticipantProductEndpoint: (meetingId, participantId, productId) => `meeting/${meetingId}/participant/${participantId}/product/${productId}`,
            addParticipant: `meeting/participant`,
            pay: 'transaction/payinscription',
            getCancelEndpoint: (meetingId, orgId) => `meeting/${meetingId}/participants/cancel?organizationId=${orgId}`,
            getJuryEndpoint: (meetingId) => `meeting/${meetingId}/jury`,
            getAllJuryEndpoint: (meetingId) => `meeting/${meetingId}/juries`,
            getParticipantTypeUpdateEndpoint: (meetingId, orgId) => `meeting/${meetingId}/participant?organizationId=${orgId}`,
            getFilteredParticipants: (meetingId) => `meeting/${meetingId}/filtered-participants`,
            getParticipantProductsDeleteEndpoint: (meetingId, participantId) => `meeting/${meetingId}/participant/${participantId}/products`
        },

        activity: {
            main: 'activity',
            vitrine: 'activity-vitrine',
            getActivityEndpoint: (activityId) => `activity/${activityId}`,
            getDeleteEndpoint: (activityId, orgaId) => `activity/${activityId}?organizationId=${orgaId}`,
            saveParticipantsActivityEndpoint: (meetingId) => `meeting/${meetingId}/participants-activities`,
            getParticipantsForActivityEndpoint: (meetingId, activityId) => `meeting/${meetingId}/activity/${activityId}/participants`,
            getMeetingPArticipantActivityEndpoint: (meetingId, participantId, activityId) => `meeting/${meetingId}/participant/${participantId}/activity/${activityId}`,
            getVitrinesEndpoint: (activityId) => `activity/${activityId}/vitrines`
        },

        vitrine: {
            all: 'vitrines',
            main: 'vitrine',
            approval: 'vitrine/approval',
            getDelete: (vitrineId, organizationId) => `vitrine/${vitrineId}?organizationId=${organizationId}`
        },

        stand: {
            all: 'stands',
            main: 'stand'
        },

        user: {
            main: 'user',
            users: 'users',
            permissions: 'authorization/roles/permissions',
            closeNotif: 'user/closeFirstLoginNotification',
            getUserEndpoint: (userId) => `user/${userId}`,
            getUserOrganizationEndpoint: (userId) => `user/${userId}/organizations/`,
            getUserMembershipsEndpoint: (userId) => `user/${userId}/organizations/member`,
            getRemoveUserFromOrganizationEndpoint: (userId, memberId) => `user/${userId}/member/${memberId}`,
            getChangePasswordEndpoint: (userId: number) => `users/${userId}/reset-password`,
            getUserMeetingsNotTakenPlaceEndpoint: (userId: number) => `user/${userId}/meetings-participants`,
        },

        upload: {
            main: 'upload',
            venue: 'upload/venue/',
            mainVenue: 'upload/venue/main',
            standImg: 'upload/stand/images',
            publication: 'upload/publication/main'
        },
        show: {
            all: 'shows',
            main: 'show',
            getShowAudiencesEndpoint: (showId) => `show/${showId}/audiences`,
            getShowDeleteEndpoint: (showId, ctxId) => `show/${showId}?organizationId=${ctxId}`,
            getShowArtistsEndpoint: (showId) => `show/${showId}/artists`,
            getAllArtists: `artists`,
            getShowManagersEndpoint: (showId) => `show/${showId}/managers`,
            getShowContactEndpoint: (showId) => `show/${showId}/contact`,
            getShowContactDeleteEndpoint: (showId, contactId) => `show/${showId}/contact/${contactId}`,
            getShowContactsEndpoint: (showId) => `show/${showId}/contacts`,
            getShowFavoritesEndpoint: (showId) => `show/${showId}/favorites`,
            editShowFavoritesEndpoint: (showId) => `show/${showId}/show-favorites`,
            getShowClassificationsEndpoint: (showId) => `show/${showId}/classifications`,
            showInfoEndpoint: `show/info`,
            getShowClassificationsListEndpoint: 'shows/classifications',
            audience: `show/audiences`,
            artists: `show/artists`,
            managers: `show/managers`,
            classification: `show/classifications`,
            approval: 'show/approval',
            tourShows: 'tour-shows',
            getTourShowSingleEndpoint: (tourShowId) => `tour-show/${tourShowId}`,
            getTourShowInterestsEndpoint: (tourShowId) => `tour-show/${tourShowId}/interest`,
            getShowMarketingEndpoint: (showId) => `show/${showId}/marketing`
        },

        venue: {
            all: 'venues',
            main: 'venue',
            approval: 'venue/approval',
            getVenueEndpoint: (venueId, organizationId) => `venue/${venueId}?organizationId=${organizationId}`,
            getVenueConfigurationEndpoint: (venueId) => `venue/${venueId}/configuration`,
            getVenueConfigurationsEndpoint: (venueId) => `venue/${venueId}/configurations`,
            getVenuesFromOrganizationEndpoint: (organizationId) => `venues?organizationId=${organizationId}`,
            getDeleteVenueConfigurationEndpoint: (venueId, confId) => `venue/${venueId}/configuration/${confId}`,
            configuration: `venue/configuration`,
            configurations: `venue/configurations`
        },

        product: {
            all: 'products',
            main: 'product',
            getProductEndpoint: (productId) => `product/${productId}`
        },

        cart: {
            main: 'cart',
            cartProduct: 'cart-product',
            getCartEndpoint: (organizationId) => `cart?organizationId=${organizationId}`,
            getUserCartEndpoint: (userId) => `cart?userId=${userId}`,
            getCartProductEndpoint: (productId) => `cart-product/${productId}`
        },

        transaction: {
            main: 'transaction/getcard',
            save: 'transaction/savecard',
            checkout: 'transaction/checkout',
            refund: 'transaction/refund',
            membership: 'transaction/paymembership',
            getHistoryEndpoint: (organizationId) => `transactions?organizationId=${organizationId}`,
            getHistoryProductTypeEndpoint: (organizationId, productTypeId) => `transactions?organizationId=${organizationId}&productTypeId=${productTypeId}`,
            getUserHistoryEndpoint: (userId) => `transactions?userId=${userId}`,
            getUserHistoryProductTypeEndpoint: (userId, productTypeId) => `transactions?userId=${userId}&productTypeId=${productTypeId}`,
            getCardsEndpoint: (organizationId) => `transaction/getcard?organizationId=${organizationId}`,
            getUserCardsEndpoint: (userId) => `transaction/getcard?userId=${userId}`,
            getDeleteCardEndpoint: (cardId, organizationId) => `transaction/paymentmethod/${cardId}/${organizationId}`,
            getTransactionProductsEndpoint: (transactionId) => `transaction/${transactionId}/products`
        },

        reports: {
            getBillingEndpoint: (dateFrom: string, dateTo: string) => `report/organizations?dateFrom=${dateFrom}&dateTo=${dateTo}`,
            getProductsEndpoint: (dateFrom: string, dateTo: string) => `report/products?dateFrom=${dateFrom}&dateTo=${dateTo}`,
            getVitrinesReportEndpoint: (meetingId) => `report/meeting/${meetingId}/vitrines`,
            getParticipantReportEndpoint: (meetingId) => `report/meeting/${meetingId}/participants`,
            generateSubmittedShowsReport: (tourId, orgId) => `report/tour-show/${tourId}/${orgId}`,
            generateSubmittedShowsInterestsReport: (tourId, orgId) => `report/tour-show/interests?tourId=${tourId}&orgId=${orgId}`,
            getMembersEndpoint: (orgId) => `report/network/${orgId}/members`,
            getMemberFavoritesEndpoint: (orgId: number) => `report/shows/${orgId}/favorites`,
            getCalendarXlsxEndPoint: (orgId: number) => `report/programs?organizationId=${orgId}`,
            users: 'report/users'
        },
        iCalendar: {
            getCalendarICS: () => `programs/export`
        },
        publication: {
            all: 'publications',
            getMainEndpoint: (orgId) => `publication?organizationId=${orgId}`,
            getSingleEndpoint: (pubId, orgId) => `publication/${pubId}?organizationId=${orgId}`,
            getApprovalEndpoint: (orgId) => `publication/approval?organizationId=${orgId}`
        },
        promotion: {
            all: 'promotions',
            getMainEndpoint: (orgId) => `promotion?organizationId=${orgId}`,
            getSingleEndpoint: (proId, orgId) => `promotion/${proId}?organizationId=${orgId}`,
            getApprovalEndpoint: (orgId) => `promotion/approval?organizationId=${orgId}`
        },

        notifications: {
            all: 'notifications',
            allArchives: 'notifications/archive',
            count: 'notifications/count',
            getSingleEndpoint: (notifId) => `notification/${notifId}`,
            getSingleArchiveEndpoint: (notifId) => `notification/${notifId}/archive`
        },

        tour: {
            all: 'tours',
            hide: 'tour/visible-tour',
            main: 'tour',
            getSingleEndpoint: (tourId: number) => `tour/${tourId}`,
            paid: 'tours/calendar',
            diffuseur: 'tour',
            getDiffuseurEndpoint: (tourId: number) => `tour/${tourId}/diffuser`,
            getAllDiffuseursEndpoint: (tourId: number) => `tour/${tourId}/diffuser/all`,
            getOneDiffuseursEndpoint: (tourId: number, diffuseurId: number) => `tour/${tourId}/diffuser/${diffuseurId}`,
            getTourAdmins: (tourId) => `tour/${tourId}/admin`,
            addTourAdmin: (tourId) => `tour/${tourId}/admin`,
            deleteTourAdmin: (tourId, userId) => `tour/${tourId}/admin/${userId}`,
            HideTourForOrganization: `tour/visible-tour`
        },

        tourShow: {
            all: 'tour-shows',
            main: 'tour-show',
            getTourShowDeleteEndpoint: (showId, ctxId) => `tour-show/${showId}?organizationId=${ctxId}`,
            interest: 'tour-show/interest',
            getInterestsEndpoint: (tourShowId) => `tour-show/${tourShowId}/interests`,
            singleInterestEndpoint: (tourShowId) => `tour-show/${tourShowId}/interest`
        },

        catalog: 'catalog',

        reset_password: environment.AZ_EDIT_REDIR,

        auth: {
            login: 'auth/login',
            askForNewPassword: 'auth/reset-password-form',
            resetPassword: 'auth/reset-password',
            logout: '',
            refreshToken: 'auth/refresh-token',
            register: 'auth/register',
            emailConfirmationRoute: `auth/confirm-email`,
            confirmEmailChangeRequest: 'auth/confirm-email-change-request'
        },
        timezone: {
            all: 'timezones'
        }
    };
    public imgFallback = '/assets/images/notfound-square.png';
    public maxImageSize = 20 * Math.pow(1024, 2);
    public notifWelcome = '/assets/images/scene-pro-orange.png';
    public pagination = {
        offset: 0,
        limit: 12
    };
    public passwordRegex = /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@$!%*?&])[A-Za-z\d@$!_\-\\\/\|\'\"\`%*?&]{8,}/;
    public printActivityChoicesCSS =
        ".hideForPrint{display: none}.print-content{overflow: auto;position: absolute;top: 0;bottom: 0;left: 0;right: 0}table{width: 100%;table-layout: fixed;background-color: #fff;font-family: 'Lato', sans-serif;font-size: 12px}table tr{box-shadow: 1px 1px black}.tabHeader{font-family: 'Lato', sans-serif}.tabHeader .leftColum .tabHeader__logo{}.tabHeader .leftColum .tabHeader__title{font-weight: 900;font-size: 24px;margin-bottom: 0px}.tabHeader .leftColum .tabHeader__subtitle{font-weight: 400;font-size: 26px;margin-top: 0px;margin-bottom: 0px;padding-bottom: 15px}.activityRow .activity_title{font-weight: 900}table tr .choices{width: 15px}table tr td{padding: 5px 0px}table tr .names .rightColum{overflow: visible;display: flex;position:relative;justify-content: flex-start}table tr .names .participantName{display: block;position: absolute;top: 50%;left: 50%;transform: translate(-50%) rotate(-90deg)}table tr .names, table tr .checkedBoxes{border-left: 1px solid #797775}table tr .leftColum{width: 30%;text-align: left}table tr .leftColum .title{font-size: 22px !important;font-family: 'Montserrat', sans-serif;margin-top: 0;margin-bottom: 0}table tr .leftColum svg{margin-bottom: 45px}table tr .rightColum{overflow: hidden;display: flex;justify-content: center}table tr .rightColum .item{flex-grow: 1;align-items: center}table tr .rightColum .item .participantName{white-space: nowrap}table tr .bubble_capacity{display: unset;font-size: 12px;color: #e2006c;padding: 2px 10px !important;background: rgba(254, 84, 77, 0.25);border-radius: 5px}";
    public standsLabelRegex = '^[aA-zZ]-{1}[0-9]+';
    public taxes = {
        TPS: 0.05,
        TVQ: 0.09975
    };
    public time_format_default = 'HH:mm:ss';
    public time_format_simple = 'HH:mm:ss';
    public vimeoVideoApi = 'https://vimeo.com/api/oembed.json?url=';
    public default_TIMEOUT_INDEXED_DB = 20000;
}
